<template>
	<div v-if="isSuperAdmin">
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
	<div v-else>
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import SpatialTablesActions from './PmSpatialTablesActions';

//import constants from '@/utils/constants';
import { isSuperAdmin } from '../../api/common';
//import constants from '../../utils/constants';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmspatialtablesgrid',
	data() {
		return {
			model: 'pmspatialtables',
			actions: SpatialTablesActions.actions,
			masterDetailColumnVisibles: {
				title: true,
				modelname: true,
				datasourcetypename: true,
				icon: true,
				iconbypropertyname: true,
				description: true,
				organizationname: true,
				visibility: true,
				disabled: true
			},
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				visibility: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			isSuperAdmin: false
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		// eslint-disable-next-line no-unused-vars

		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }
					/* { field: 'model', op: 'ne', data: constants.NOTIFICATIONS_MODEL } */
				]
			};
		}
	},

	methods: {}
};
</script>

<style lang="postcss" scoped></style>
