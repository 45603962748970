// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableSpatialTables(spatialtable) {
	const controller = '/pmspatialtables/disableSpatialTables';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		spatialtable,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableSpatialTables(spatialtable) {
	const controller = '/pmspatialtables/enableSpatialTables';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		spatialtable,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

// New actions - Visibility enable/disable
export async function disableVisibilities(spatialtable) {
	const controller = '/pmspatialtables/disableVisibilities';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		spatialtable,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableVisibilities(spatialtable) {
	const controller = '/pmspatialtables/enableVisibilities';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		spatialtable,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
