import { disableSpatialTables, enableSpatialTables, disableVisibilities, enableVisibilities } from '@/api/pmspatialtables';

const disableSpatialTable = {
	id: 'disableSpatialTable',
	selectionType: 'multiple',
	label: 'pmspatialtables.actions.disableSpatialTable',
	functionality: 'UPDATE_PMSPATIALTABLES',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmspatialtables.actions.disableSpatialTable');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmspatialtablesid: registries[i].pmspatialtablesid
			});
		}

		const data = await disableSpatialTables(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableSpatialTable = {
	id: 'enableSpatialTable',
	selectionType: 'multiple',
	label: 'pmspatialtables.actions.enableSpatialTable',
	functionality: 'UPDATE_PMSPATIALTABLES',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmspatialtables.actions.enableSpatialTable');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmspatialtablesid: registries[i].pmspatialtablesid
			});
		}

		const data = await enableSpatialTables(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

// New actions - Visibility enable/disable
const disableVisibility = {
	id: 'disableVisibility',
	selectionType: 'multiple',
	label: 'pmspatialtables.actions.disableVisibility',
	functionality: 'UPDATE_PMSPATIALTABLES',
	checkAvailability: function (instance) {
		return instance && instance.visibility === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmspatialtables.actions.disableVisibility');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmspatialtablesid: registries[i].pmspatialtablesid
			});
		}

		const data = await disableVisibilities(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVisibility = {
	id: 'enableVisibility',
	selectionType: 'multiple',
	label: 'pmspatialtables.actions.enableVisibility',
	functionality: 'UPDATE_PMSPATIALTABLES',
	checkAvailability: function (instance) {
		return instance && instance.visibility === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmspatialtables.actions.enableVisibility');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmspatialtablesid: registries[i].pmspatialtablesid
			});
		}

		const data = await enableVisibilities(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableSpatialTable, enableSpatialTable, disableVisibility, enableVisibility]
};
